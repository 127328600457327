import { getRequest, postRequest, deleteRequest, putRequest } from '../request'

export const getRoleList = (res) => {
    return getRequest('/permis/roles', res)
}

export const getRoleListPage = (res) => {
    return postRequest('/permis/roles', res)
}

export const saveRole = (res) => {
    if (res.id) {
        //更新角色
        return putRequest('/permis/role', res)
    }
    else {
        //添加角色
        return postRequest('/permis/role', res)
    }
}

export const deleteRole = (rid) => {
    return deleteRequest('/permis/role/' + rid, rid)
}

export default { getRoleList, saveRole, deleteRole }