<!-- 角色权限设置 -->
<template>
  <div class="roleTool">
    <a-form layout="inline" :model="roleInfo" ref="formRef" :rules="rules">
      <a-form-item name="code">
        <a-input
          addon-before="ROLE_"
          placeholder="请输入角色代码"
          v-model:value="roleInfo.code"
        />
      </a-form-item>
      <a-form-item name="name">
        <a-input placeholder="请输入角色名称" v-model:value="roleInfo.name" />
      </a-form-item>
      <a-button type="primary" @click="doAddRole" v-if="isAdd">
        <template #icon>
          <PlusOutlined />
        </template>
        添加角色
      </a-button>
      <a-button type="primary" @click="doAddRole" v-else>
        <template #icon>
          <EditOutlined />
        </template>
        编辑角色
      </a-button>
    </a-form>
  </div>
  <div class="roleMain">
    <a-collapse accordion v-model:activeKey="activeKey" @change="onChange">
      <a-collapse-panel
        :key="role.id.toString()"
        :header="role.name"
        v-for="role in roleList"
      >
        <a-card title="可访问资源">
          <template #extra>
            <a-button type="link" size="small">
              <SvgIcon
                iconName="selectall"
                className="svg15"
                @click.stop="doSelectAll"
              />
            </a-button>
            <a-button type="link" size="small">
              <SvgIcon
                iconName="invert"
                className="svg15"
                @click.stop="doInvert"
              />
            </a-button>
            <a-button type="link" size="small" @click.stop="doEmpty">
              <SvgIcon iconName="empty" className="svg15" />
            </a-button>
          </template>
          <a-tree
            checkable
            selectable
            :tree-data="menuList"
            v-model:checkedKeys="checkedKeys"
            v-model:expandedKeys="expandedKeys"
            v-model:selectedKeys="selectedKeys"
            :replace-fields="replaceFields"
          >
          </a-tree>
          <div style="display: flex; justify-content: flex-end">
            <a-button
              size="small"
              style="margin-right: 6px"
              @click="unDoUpdateMenus"
            >
              <UndoOutlined />取消修改
            </a-button>
            <a-button
              size="small"
              type="primary"
              @click="doUpdateMenus(role.id)"
            >
              <SaveOutlined />确认修改
            </a-button>
          </div>
        </a-card>
        <template #extra>
          <a-button
            type="link"
            size="small"
            danger="true"
            @click.stop="doDeleteRole(role)"
          >
            <DeleteOutlined />
          </a-button>
          <a-button
            type="link"
            size="small"
            v-if="isAdd"
            @click.stop="startEditRole(role)"
          >
            <EditOutlined />
          </a-button>
          <a-button type="link" size="small" v-else @click.stop="startAddRole">
            <PlusOutlined />
          </a-button>
        </template>
      </a-collapse-panel>
    </a-collapse>
  </div>
</template>

<script>
import {
  PlusOutlined,
  DeleteOutlined,
  EditOutlined,
  UndoOutlined,
  SaveOutlined,
  ExclamationCircleOutlined,
} from "@ant-design/icons-vue";
import {
  reactive,
  ref,
  toRefs,
  onBeforeMount,
  onMounted,
  createVNode,
} from "vue";
import { message, Modal } from "ant-design-vue";
import { getRoleList, saveRole, deleteRole } from "@/api/permis/role";
import { getMenuTreeList, getMenuByRid, updateRoleMenus } from "@/api/menu";
import SvgIcon from "@/components/Svgicon";
export default {
  name: "Permis",
  components: {
    PlusOutlined,
    DeleteOutlined,
    EditOutlined,
    UndoOutlined,
    SaveOutlined,
    SvgIcon,
  },
  setup() {
    const formRef = ref();
    const data = reactive({
      roleInfo: {
        id: "",
        code: "",
        name: "",
      },
      replaceFields: {
        children: "children",
        title: "name",
        key: "id",
      },
      isAdd: true,
      activeKey: "", //折叠面板打开的面板（角色）
      roleList: [], //所有角色列表
      menuList: [], //所有菜单列表
      checkedKeys: [], //所有勾选的菜单列表
      expandedKeys: [],
      selectedKeys: [],
    });
    onBeforeMount(() => {});
    onMounted(() => {
      initRoleList();
      initAllMenuList();
    });
    const onChange = (key) => {
      if (key) {
        initRoleMenuList(key);
      }
    };
    const initRoleList = () => {
      getRoleList().then((resp) => {
        if (resp) {
          data.roleList = resp;
        }
      });
    };
    const initAllMenuList = () => {
      getMenuTreeList().then((resp) => {
        if (resp) {
          data.menuList = resp;
        }
      });
    };
    const initRoleMenuList = (rid) => {
      getMenuByRid(rid).then((resp) => {
        if (resp) {
          data.checkedKeys = resp;
        } else {
          data.checkedKeys = [];
        }
      });
    };
    const doAddRole = () => {
      formRef.value
        .validate()
        .then((success) => {
          if (success) {
            saveRole(data.roleInfo).then((resp) => {
              if (resp) {
                initRoleList();
                formRef.value.resetFields();
                data.activeKey = "";
                data.isAdd = true;
              }
            });
          } else {
            message.error("角色添加失败！！！");
            return false;
          }
        })
        .catch((err) => {
          console.log("error", err);
        });
    };
    const doDeleteRole = (role) => {
      Modal.confirm({
        title: "温馨提示",
        icon: createVNode(ExclamationCircleOutlined),
        okText: "确认",
        cancelText: "取消",
        content: createVNode(
          "div",
          { style: "color:red;" },
          "此操作讲永久删除【" + role.name + "】角色，是否继续？"
        ),
        onOk() {
          deleteRole(role.id).then((resp) => {
            if (resp) {
              initRoleList();
              data.activeKey = "";
            }
          });
        },
        onCancel() {
          message.info("已取消删除角色操作！！！");
          data.activeKey = "";
        },
      });
    };
    const startAddRole = () => {
      data.roleInfo.id = "";
      data.roleInfo.code = "";
      data.roleInfo.name = "";
      data.isAdd = true;
    };
    const startEditRole = (role) => {
      data.roleInfo.id = role.id;
      data.roleInfo.code = role.code.split("_")[1];
      data.roleInfo.name = role.name;
      data.isAdd = false;
    };
    const doUpdateMenus = (rid) => {
      //console.log(rid);
      console.log(data.checkedKeys);
      let res = { rid: rid, ids: data.checkedKeys };
      updateRoleMenus(res).then((resp) => {
        if (resp) {
          initRoleList();
          data.activeKey = "";
        }
      });
    };
    const unDoUpdateMenus = () => {
      data.activeKey = "";
    };
    const doEmpty = () => {
      data.checkedKeys = [];
    };
    const doSelectAll = () => {
      data.checkedKeys = data.menuList
        .map((a) => a.children)
        .flat()
        .map((item) => item.id);
    };
    const doInvert = () => {
      let allMenus = data.menuList
        .map((a) => a.children)
        .flat()
        .map((item) => item.id);
      data.checkedKeys = [...allMenus].filter((x) =>
        [...data.checkedKeys].every((y) => y !== x)
      );
      console.log(data.checkedKeys);
    };
    const rules = {
      code: [
        { required: true, message: "角色代码不能为空！", trigger: "blur" },
      ],
      name: [
        { required: true, message: "角色名称不能为空！", trigger: "blur" },
      ],
    };
    const refData = toRefs(data);
    return {
      ...refData,
      formRef,
      rules,
      initRoleList,
      initAllMenuList,
      initRoleMenuList,
      startAddRole,
      startEditRole,
      doAddRole,
      doDeleteRole,
      onChange,
      doUpdateMenus,
      unDoUpdateMenus,
      doEmpty,
      doSelectAll,
      doInvert,
    };
  },
};
</script>
<style lang='scss' scope>
.roleTool {
  display: flex;
  justify-content: flex-start;
}
.ant-input {
  width: 200px;
  //margin-right: 6px;
}
.ant-input-group {
  width: 280px;
  //padding-right: 6px;
}
.ant-form-item {
  margin-right: 6px;
}
.roleMain {
  margin-top: 10px;
}
</style>